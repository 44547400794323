import { FC, PropsWithChildren } from "react";
import { Box } from "@mui/material";

const BoxTop: FC<PropsWithChildren> = ({ children }) => {
  const styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e0e0e0",
    mb: 2,
    paddingInlineEnd: 1,
  };

  return <Box sx={styles}>{children}</Box>;
};

export default BoxTop;
