import { GraphQLError } from "graphql";

export class ApplicationError extends Error {
  constructor(message: string, details: string = message) {
    super(message);
    this.name = "ApplicationError";
  }
}

export class APIMultiError extends ApplicationError {
  errors: readonly GraphQLError[];

  constructor(message: string, errors: readonly GraphQLError[]) {
    super(message);
    this.name = "APIError";
    this.errors = errors;
  }
}

export class SaveCaseError extends ApplicationError {
  constructor(message: string, details: string = message) {
    super(message, details);
    this.name = "SaveCaseError";
  }
}
