import { FC } from "react";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";

const DataGridExportToolbar: FC = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

export default DataGridExportToolbar;
