import { FC, PropsWithChildren } from "react";
import { Paper } from "@mui/material";

const StyledPaper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        minHeight: "100%",
      }}
    >
      {children}
    </Paper>
  );
};

export default StyledPaper;
