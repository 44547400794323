/* eslint-disable quote-props */

export const listPrimaryStyles = {
  noWrap: false,
  fontSize: 12,
  width: "115px",
  color: "text.secondary",
};

export const listSecondaryStyles = {
  fontSize: 15,
  lineHeight: "20px",
  ml: "10px",
  width: "70%",
  color: "text.primary",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const listStyles = {
  display: "flex",
  justifyContent: "flex-start",
  alignContent: "center",
};
