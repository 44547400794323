"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.caseStatusNames = exports.CaseStatus = void 0;
/* eslint-disable no-unused-vars */
// NOTE:
// There is a corresponding Enum in Postgres that must be kept in sync via migrations
var CaseStatus;
(function (CaseStatus) {
    CaseStatus["New"] = "NEW";
    CaseStatus["ToDo"] = "TO_DO";
    CaseStatus["NoticeSent"] = "NOTICE_SENT";
    CaseStatus["ApptScheduled"] = "APPT_SCHEDULED";
    CaseStatus["InProgress"] = "IN_PROGRESS";
    CaseStatus["Review"] = "REVIEW";
    CaseStatus["Invoice"] = "INVOICE";
    CaseStatus["Completed"] = "COMPLETED";
    CaseStatus["Cancelled"] = "CANCELLED";
})(CaseStatus || (exports.CaseStatus = CaseStatus = {}));
exports.caseStatusNames = {
    [CaseStatus.New]: "New",
    [CaseStatus.ToDo]: "To Do",
    [CaseStatus.NoticeSent]: "Notice Sent",
    [CaseStatus.ApptScheduled]: "Appt. Scheduled",
    [CaseStatus.InProgress]: "In Progress",
    [CaseStatus.Review]: "Review",
    [CaseStatus.Invoice]: "Invoice",
    [CaseStatus.Completed]: "Completed",
    [CaseStatus.Cancelled]: "Cancelled",
};
