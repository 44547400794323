import { FC, ReactNode } from "react";
import { List, ListItem, ListItemText } from "@mui/material";

import { listPrimaryStyles, listSecondaryStyles, listStyles } from "./ListsStyles";

const listSx = {
  py: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const StyledList: FC<Parameters<typeof List>[0]> = (props) => {
  return <List sx={listSx} {...props} />;
};

const Nbsp = <>&nbsp;</>;

export const Item = (props: {
  primary: ReactNode;
  secondary?: ReactNode;
  children?: ReactNode;
}) => (
  <ListItem disableGutters>
    <ListItemText
      primaryTypographyProps={listPrimaryStyles}
      secondaryTypographyProps={listSecondaryStyles}
      sx={listStyles}
      {...props}
      secondary={props.secondary || props.children || Nbsp}
    />
  </ListItem>
);

export { StyledList as List };
