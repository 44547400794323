import { GridFilterModel } from "@mui/x-data-grid-pro";

const mostRecentCaseFilter = { field: "mostRecent", operator: "is", value: "Yes" };

export const mostRecentCaseFilterModel: GridFilterModel = {
  items: [mostRecentCaseFilter],
};

export const activeCasesFilterModel: GridFilterModel = {
  items: [{
    field: "active", operator: "is", value: "Yes",
  }],
};

export const businessInsightsFilterModel: GridFilterModel = {
  items: [
    { field: "subject.subjectType", operator: "is", value: "Organization" },
    mostRecentCaseFilter,
  ],
};

export const residenceInsightsFilterModel: GridFilterModel = {
  items: [
    { field: "subject.subjectType", operator: "is", value: "Residence" },
    mostRecentCaseFilter,
  ],
};

export const blankFilterModel: GridFilterModel = {
  items: [],
};
