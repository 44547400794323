import { useTheme } from "@mui/material/styles";
import GridTextField from "./GridTextField";
import { GridTextFieldProps } from "./GridTextField";


const GridDateField = (props: GridTextFieldProps) => {
  const theme = useTheme();

  const dateFieldStyle = {
    backgroundColor: theme.palette.mode === "dark" ? "#333" : "fff",
  };

  return (
    <GridTextField
      {...props}
      type="date"
      InputLabelProps={{ shrink: true }}
      InputProps = {{
        style: dateFieldStyle,
      }}
    />
  );
};

export default GridDateField;
