import { FC } from "react";
import { Button, ButtonProps } from "@mui/material";

interface SaveButtonProps {
  isSubmitting: boolean;
  isEditing: boolean;
  canEdit: boolean;
  fullWidth?: boolean;
}

const SaveButton: FC<SaveButtonProps> = ({
  isSubmitting = false,
  isEditing = false,
  canEdit = false,
  fullWidth = false,
}) => {
  if (isEditing && !canEdit) return null;

  const buttonProps: ButtonProps = {
    type: "submit",
    variant: "contained",
    size: "large",
    color: "success",
    disabled: isSubmitting,
    fullWidth: fullWidth,
  };

  if (isEditing) {
    return <Button {...buttonProps}>Save</Button>;
  }

  return <Button {...buttonProps}>Save</Button>;
};

export default SaveButton;
