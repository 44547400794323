import { useMemo } from 'react';

const config$5 = {
    apiKey: "AIzaSyCTpbe_Byj2Mfnzp5pVtAjiTbPfxvdQowk",
    authDomain: "auditaware-dev.firebaseapp.com",
    projectId: "auditaware-dev",
    storageBucket: "auditaware-dev.appspot.com",
    messagingSenderId: "675182025385",
    appId: "1:675182025385:web:440ad540bfd4cad0da67b8",
    measurementId: "G-MWQ980R0ZR"
};

const config$4 = {
    apiKey: "AIzaSyBxI-giZacQr5lbCwUI4H41pyxR8R1MCIY",
    authDomain: "auditaware-qa.firebaseapp.com",
    projectId: "auditaware-qa",
    storageBucket: "auditaware-documents-qa",
    messagingSenderId: "659740156875",
    appId: "1:659740156875:web:bc837c5299159c2649cc4e",
    measurementId: "G-93KBXYH515",
};

const config$3 = {
    apiKey: "AIzaSyCR0fITWVaHk1AwYfDQbtpbVw1Hwrf-HFw",
    authDomain: "auditaware-production.firebaseapp.com",
    projectId: "auditaware-production",
    storageBucket: "auditaware-documents-production",
    messagingSenderId: "456554260634",
    appId: "1:456554260634:web:c41fd034cc10d061571723",
    measurementId: "G-JZL1C9H9TZ"
};

const config$2 = {
    apiKey: "AIzaSyCgFsOw6jpmf8vHuN92SsaNZYeiaxu_uOs",
    authDomain: "auditaware-external-dev-c2fa7.firebaseapp.com",
    projectId: "auditaware-external-dev",
    storageBucket: "auditaware-external-dev.appspot.com",
    messagingSenderId: "309348785766",
    appId: "1:309348785766:web:228d388c014830b4bc57a7",
    measurementId: "G-TYH9RCC9HG",
};

const config$1 = {
    apiKey: "AIzaSyC6SQgdAw7BcfM9qbyooOukUyuhPbWSJ2Q",
    authDomain: "auditaware-external-qa.firebaseapp.com",
    projectId: "auditaware-external-qa",
    storageBucket: "auditaware-external-qa.appspot.com",
    messagingSenderId: "500593112311",
    appId: "1:500593112311:web:a32f2bd34e7f468f2b8ff2",
    measurementId: "G-JT7EJ00X8H",
};

const staticConfig = { valuationWatercraftPath: "/watercraft",
};
const config = {
    production: Object.assign(Object.assign({}, staticConfig), { firebaseConfig: config$3, firebaseExternalConfig: config$1, fireSubApi: "https://sso-api.auditaware.app/", auditAwareApi: "https://api.auditaware.app/", auditAwareGraph: "https://graph.auditaware.app/", valuationApi: "https://valuation-api.auditaware.app/", ssoClient: "https://sso.auditaware.app/", caseClient: "https://case.auditaware.app/", canvasClient: "https://canvas.auditaware.app/", valuationClient: "", contactClient: "" }),
    qa: Object.assign(Object.assign({}, staticConfig), { firebaseConfig: config$4, firebaseExternalConfig: config$1, fireSubApi: "https://qa-sso-api.auditaware.app/", auditAwareApi: "https://qa-api.auditaware.app/", auditAwareGraph: "https://qa-graph.auditaware.app/", valuationApi: "https://qa-valuation-api.auditaware.app/", ssoClient: "https://qa-sso.auditaware.app/", caseClient: "https://qa-case.auditaware.app/", canvasClient: "https://qa-canvas.auditaware.app/", valuationClient: "https://qa-valuation.auditaware.app/", contactClient: "https://qa-contact.auditaware.app/" }),
    uat: Object.assign(Object.assign({}, staticConfig), { firebaseConfig: config$4, firebaseExternalConfig: config$1, fireSubApi: "https://qa-sso-api.auditaware.app/", auditAwareApi: "https://qa-api.auditaware.app/", auditAwareGraph: "https://qa-graph.auditaware.app/", valuationApi: "https://qa-valuation-api.auditaware.app/", ssoClient: "https://qa-sso.auditaware.app/", caseClient: "https://qa-case.auditaware.app/", canvasClient: "https://qa-canvas.auditaware.app/", valuationClient: "https://qa-valuation.auditaware.app/", contactClient: "https://qa-contact.auditaware.app/" }),
    development: Object.assign(Object.assign({}, staticConfig), { firebaseConfig: config$5, firebaseExternalConfig: config$2, fireSubApi: "http://localhost:4001/", auditAwareApi: "http://localhost:7001/", auditAwareGraph: "http://localhost:7001/", valuationApi: "http://localhost:4501/", ssoClient: "http://sso.apps.localhost:6002/", caseClient: "http://case.apps.localhost:6010/", canvasClient: "http://canvas.apps.localhost:19006", valuationClient: "http://valuation.apps.localhost:5173", contactClient: "http://localhost:5173" }),
};

const detectStage = (namespace, hostname) => {
    if (hostname === `${namespace}.auditaware.app`) {
        return "production";
    }
    if (hostname === `uat-${namespace}.auditaware.app`) {
        return "uat";
    }
    if (hostname === `qa-${namespace}.auditaware.app`) {
        return "qa";
    }
    return "development";
};
function useAuditAwareConfig(namespace) {
    const { hostname } = window.location;
    return useMemo(() => {
        const releaseStage = detectStage(namespace, hostname);
        const useFirebaseEmulator = releaseStage === "development";
        const devMode = ["qa", "uat", "development"].indexOf(releaseStage) >= 0;
        const authCookieName = `auditaware_auth_${releaseStage}`;
        return Object.assign(Object.assign({}, config[releaseStage]), { releaseStage,
            devMode,
            useFirebaseEmulator,
            authCookieName });
    }, [namespace, hostname]);
}

// eslint-disable-next-line max-len
const MuiXLicense = "d62309630282a072a310e03de9269e37Tz05MjI5OCxFPTE3NDk3NjU2NTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";

export { MuiXLicense, useAuditAwareConfig };
