import { FC, useCallback } from "react";
import { Formik } from "formik";
import Form from "./NoteForm";

import { User, NoteSchema as validationSchema } from "auditaware-types";
import { useSnackbarContext } from "../../../hooks/useSnackbarContext";
import InitialsAvatar from "../../shared/InitialsAvatar";
import { Box, Typography } from "@mui/material";
import { useDrawer } from "auditaware-ui";
import { useCreateNote } from "../../../hooks/noteHooks";
import { useUserDoc } from "@reasongcp/react-fire-sub";

type CreateNoteProps = {
  caseId: string;
};

const initialValues = {
  text: "",
};

const CreateNote: FC<CreateNoteProps> = ({ caseId }) => {
  const [createNote] = useCreateNote(caseId);

  // TODO: Load user from API.
  const user = useUserDoc() as User | null;

  const { toggleDrawer } = useDrawer();
  const { setSuccess, setError } = useSnackbarContext();

  const handleSubmit = useCallback(
    async (values: { text: string }) => {
      try {
        await createNote(values);
        setSuccess("Note created");
        toggleDrawer("createNote");
      } catch (err) {
        console.error(err);
        setError("Error creating note");
      }
    },
    [createNote, setError, setSuccess, toggleDrawer]
  );

  if (!user) return null;

  return (
    <Box
      component="div"
      className="test">
      <InitialsAvatar user={user} />
      <Typography variant="h6" sx={{ ml: 1 }}>
        {user.displayName}
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form drawerId="createNote"/>
      </Formik>
    </Box>
  );
};

export default CreateNote;
