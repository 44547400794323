import { FC, useMemo } from "react";
import { Grid, Paper } from "@mui/material";
import { DataGridPro, GridColDef, GridColumnGroupingModel } from "@mui/x-data-grid-pro";

import { Subject, Case, User } from "auditaware-types";

import DataGridExportToolbar from "../../shared/DataGridExportToolbar";
import BoxHeader from "../../shared/BoxHeader";
import BoxTop from "../../shared/BoxTop";
import Spinner from "../../shared/LogoSpinner/Spinner";
import { useSubjects } from "../../../hooks/subjectHooks";
import useAssignees from "../../../hooks/useAssignees";

type SubjectAndCase = {
  subject: Subject;
  case?: Case;
};

type ReportRow = Pick<User, "displayName" | "id"> & Record<string, number>;

const SEP = "__" as const;

const typeKey = (subject: Subject, c?: Case) =>
  [subject.subjectType, c?.caseType || "No Case", c?.status || "NONE"].join(SEP);

const StatusReport: FC = () => {
  const { error, loading, subjects } = useSubjects({ loadCases: true });
  const assignees = useAssignees();

  const records = useMemo(
    () =>
      subjects.flatMap<SubjectAndCase>((subject) => {
        const cases = subject.cases || [];

        if (!cases.length) return { subject };

        return cases.map((c) => ({ subject, case: c }));
      }),
    [subjects]
  );

  const types: Record<string, number> = useMemo(
    () =>
      records.reduce(
        (acc, { subject: s, case: c }) => {
          const type = typeKey(s, c);
          acc[type] = (acc[type] || 0) + 1;
          return acc;
        },
        {} as Record<string, number>
      ),
    [records]
  );

  const columnGroups: GridColumnGroupingModel = useMemo(() => {
    const groups = Object.keys(types).reduce(
      (acc, type) => {
        const [subjectType, caseType, status] = type.split(SEP);

        if (!acc[subjectType]) acc[subjectType] = {};
        if (!caseType) {
          acc[subjectType]["No Case"] = { NONE: type };
          return acc;
        }

        if (!acc[subjectType][caseType]) acc[subjectType][caseType] = {};
        if (!acc[subjectType][caseType][status]) {
          acc[subjectType][caseType][status] = type;
        }

        return acc;
      },
      {} as Record<string, Record<string, Record<string, string>>>
    );

    return Object.keys(groups).reduce((acc, subjectType) => {
      const caseTypes = groups[subjectType];

      const caseGroups = Object.keys(caseTypes).reduce((acc, caseType) => {
        const statuses = caseTypes[caseType];

        const statusGroups = Object.keys(statuses).map((status) => ({
          field: statuses[status],
        }));

        return [
          ...acc,
          {
            groupId: caseType,
            headerName: caseType,
            children: statusGroups,
          },
        ];
      }, [] as GridColumnGroupingModel);

      return [
        ...acc,
        {
          groupId: subjectType,
          headerName: subjectType,
          children: caseGroups,
        },
      ];
    }, [] as GridColumnGroupingModel);
  }, [types]);

  const rows = useMemo(() => {
    const unassigned = { displayName: "Unassigned", id: undefined };
    const topRows = [unassigned, ...assignees].map(({ displayName, id }) => {
      const assignments = records.filter(({ case: c }) => id === c?.assignee?.id);

      return assignments.reduce(
        (acc, { subject, case: c }) => {
          const key = typeKey(subject, c);
          acc[key] = (acc[key] || 0) + 1;
          return acc;
        },
        { displayName, id: id || "unassigned" } as ReportRow
      );
    });
    return [...topRows, { displayName: "Total", id: "total", ...types }];
  }, [assignees, records, types]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "displayName",
        headerName: "Assignee",
        display: "flex",
        flex: 1,
      },
      ...Object.keys(types)
        .sort()
        .map((type) => {
          return {
            field: type,
            headerName: type.split(SEP).pop(),
            valueGetter: (value: any) => (value ? value : 0),
            flex: 1,
          };
        }),
    ],
    [types]
  );

  if (error || loading) return <Spinner />;

  return (
    <Grid item xs md={12} lg={12}>
      <Paper>
        <BoxTop>
          <BoxHeader>Status Report</BoxHeader>
        </BoxTop>
        <DataGridPro
          rows={rows}
          columns={columns}
          columnGroupingModel={columnGroups}
          slots={{ toolbar: DataGridExportToolbar }}
        />
      </Paper>
    </Grid>
  );
};

export default StatusReport;
