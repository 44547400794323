import { FC } from "react";
import { Item, List } from "../../shared/List";
import { ResidenceData } from "auditaware-types";

type ResidenceViewProps = {
  residenceData: ResidenceData;
};

const ResidenceView: FC<ResidenceViewProps> = ({
  residenceData: { exemptionPercentage, score, lastSaleOn },
}) => {
  return (
    <List>
      <Item primary="Exemption Percentage" secondary={exemptionPercentage} />
      <Item primary="Score" secondary={score ? score : "Not Scored"} />
      <Item primary="Last Sale On" secondary={lastSaleOn} />
    </List>
  );
};

export default ResidenceView;
