import React, { FC, useCallback } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Dropzone, {
  IMeta,
  StatusValue,
  IFileWithMeta,
  IDropzoneProps,
  MethodValue,
} from "react-dropzone-uploader";

import "react-dropzone-uploader/dist/styles.css";
import { gql, useMutation } from "@apollo/client";
import { GET_SUBJECT } from "../../../hooks/subjectHooks";
import { GET_CASE } from "../../../hooks/cases";

const CREATE_UPLOAD_PARAMS = gql`
  mutation Mutation($caseId: ID!, $input: AttachmentInput!) {
    createAttachment(caseId: $caseId, input: $input) {
      id
      url
    }
  }
`;

const FINALIZE_UPLOAD = gql`
  mutation Mutation($attachmentId: ID!) {
    finalizeAttachment(attachmentId: $attachmentId) {
      id
      url
    }
  }
`;

type UploadParams = {
  createAttachment: {
    id: string;
    url: string;
  };
};

type UploadProps = {
  caseId: string;
  uploadPrompt?: FC;
};

type CustomMeta = IMeta & {
  uploadUuid: string;
};

type IFileWithCustomMeta = IFileWithMeta & {
  meta: CustomMeta;
};

const UploadPrompt: IDropzoneProps["inputContent"] = (allFiles, extra) => {
  const { active } = extra;

  const propogateClick = (e: React.MouseEvent) => {
    const label = e.currentTarget.parentNode;
    const input = label?.querySelector("input");
    if (input) input.click();
  };

  return (
    <Button
      key="upload"
      type="button"
      variant="contained"
      color="primary"
      onClick={propogateClick}
      startIcon={<AddIcon />}
      sx={{ borderRadius: 24 }}
    >
      {active ? "Release to drop the files here" : "Drag & drop or click to select files"}
    </Button>
  );
};

const Upload: FC<UploadProps> = ({ caseId, uploadPrompt = UploadPrompt }) => {
  const [createUrlParams] = useMutation<UploadParams>(CREATE_UPLOAD_PARAMS);
  const [finalizeUpload] = useMutation(FINALIZE_UPLOAD, {
    refetchQueries: [GET_SUBJECT, GET_CASE],
  });

  const getParams = useCallback(
    async (file: IFileWithMeta) => {
      const { data, errors } = await createUrlParams({
        variables: {
          caseId,
          input: {
            contentType: file.meta.type,
            fileName: file.meta.name,
          },
        },
      });
      if (errors) {
        console.error(errors);
      }

      const { url = "", id: uploadUuid } = data?.createAttachment || {};
      const method: MethodValue = "PUT";

      return {
        url,
        method,
        body: file.file,
        meta: {
          uploadUuid,
        },
      };
    },
    [caseId, createUrlParams]
  );

  const handleChangeStatus = useCallback(
    ({ file, meta, ...ifile }: IFileWithCustomMeta, status: StatusValue) => {
      if (status !== "done") return;

      finalizeUpload({
        variables: { attachmentId: meta.uploadUuid },
      }).then(() => ifile.remove());
    },
    [finalizeUpload]
  );

  return (
    <Dropzone
      multiple={true}
      getUploadParams={getParams}
      inputContent={uploadPrompt}
      onChangeStatus={handleChangeStatus}
    />
  );
};

export default Upload;
