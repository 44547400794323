import { FC } from "react";
import { Grid } from "@mui/material";

import { Case } from "auditaware-types";

import CaseOverview from "../Case/CaseOverview";
import CaseNotes from "../Case/CaseNotes";
import CaseAttachments from "../Case/CaseAttachments";

type CaseDetailProps = {
  case?: Case;
};

const CaseDetails: FC<CaseDetailProps> = ({ case: caseData }) => {
  if (!caseData) return null;
  const { id: caseId } = caseData;

  return (
    <>
      <CaseOverview caseData={caseData} />
      <Grid container spacing={2}>
        <Grid item xs md={6} lg={6}>
          <CaseNotes caseId={caseId} notes={caseData.notes} />
        </Grid>
        <Grid item xs md={6} lg={6}>
          <CaseAttachments caseId={caseId} attachments={caseData.attachments} />
        </Grid>
      </Grid>
    </>
  );
};

export default CaseDetails;
