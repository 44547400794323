import { FC, PropsWithChildren, useMemo } from "react";
import { Avatar, Tooltip, styled } from "@mui/material";
import { GridFilterModel, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import {
  SettingsBackupRestore,
  AllInclusive,
  FilterNone,
} from "@mui/icons-material";

import { wordToCamelCase } from "../../lib/wordToCamelCase";
import { useTheme } from "@mui/material/styles";
import { subjectColorFromTheme } from "../../lib/appTypeColors";
import { subjectIconFromType } from "../../lib/appTypeIcons";
import {
  defaultColumnVisibilityModel,
  exportColumnVisibilityModel,
  businessInsightColumnVisibilityModel,
  residenceInsightColumnVisibilityModel,
} from "../../lib/columnVisibilityModels";
import {
  mostRecentCaseFilterModel,
  activeCasesFilterModel,
  businessInsightsFilterModel,
  residenceInsightsFilterModel,
  blankFilterModel,
} from "../../lib/filterModels";

const StyledIcon = styled(Avatar, {
  shouldForwardProp: (prop) => true,
})(({ color }) => ({
  "&:hover": {
    backgroundColor: color,
  },
  cursor: "pointer",
  marginInlineEnd: 8,
  height: 36,
  width: 36,
}));

type Icon = {
  tooltip: string;
  color: string;
  onClick: () => void;
};

type IconWrapperProps = PropsWithChildren<Icon>;

const IconWrapper: FC<IconWrapperProps> = ({ tooltip, color, children, onClick }) => {
  const className = wordToCamelCase(tooltip);
  const props = { className, color, onClick };
  return (
    <Tooltip title={tooltip}>
      <StyledIcon {...props}>{children}</StyledIcon>
    </Tooltip>
  );
};

interface DataGridToolbarIconsProps {
  setFilters: (x: GridFilterModel | null) => void;
  setColumnVisibilityModel: (x: GridColumnVisibilityModel | null) => void;
}

const BusinessIcon = subjectIconFromType("Organization");
const ResidenceIcon = subjectIconFromType("Residence");

const DataGridToolbarIcons: FC<DataGridToolbarIconsProps> = ({
  setFilters,
  setColumnVisibilityModel,
}) => {
  const theme = useTheme();

  const icons: IconWrapperProps[] = useMemo(
    () => [
      {
        children: <SettingsBackupRestore />,
        tooltip: "View Default",
        color: theme.palette.info.main,
        onClick: () => {
          setFilters(mostRecentCaseFilterModel);
          setColumnVisibilityModel(defaultColumnVisibilityModel);
        },
      },
      {
        children: <FilterNone sx={{ height: 19, width: 19 }} />,
        tooltip: "View Active",
        color: theme.palette.success.main,
        onClick: () => {
          setFilters(activeCasesFilterModel);
          setColumnVisibilityModel(defaultColumnVisibilityModel);
        },
      },
      {
        children: <BusinessIcon />,
        tooltip: "Businesses",
        color: subjectColorFromTheme(theme, "Organization"),
        onClick: () => {
          setFilters(businessInsightsFilterModel);
          setColumnVisibilityModel(businessInsightColumnVisibilityModel);
        },
      },
      {
        children: <ResidenceIcon />,
        tooltip: "Residences",
        color: subjectColorFromTheme(theme, "Residence"),
        onClick: () => {
          setFilters(residenceInsightsFilterModel);
          setColumnVisibilityModel(residenceInsightColumnVisibilityModel);
        },
      },
      {
        children: <AllInclusive />,
        tooltip: "View All",
        color: theme.palette.warning.light,
        onClick: () => {
          setFilters(blankFilterModel);
          setColumnVisibilityModel(exportColumnVisibilityModel);
        },
      },
    ],
    [theme, setFilters, setColumnVisibilityModel]
  );

  return (
    <div style={{ display: "flex" }}>
      {icons.map((icon, i) => (
        <IconWrapper {...icon} key={i} />
      ))}
    </div>
  );
};

export default DataGridToolbarIcons;
