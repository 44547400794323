import { FC, useMemo, useCallback } from "react";
import { Grid, Box, Paper, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GridColumnGroupingModel } from "@mui/x-data-grid-pro";

import { Subject, Case } from "auditaware-types";
import { CustomDrawer, useDrawer } from "auditaware-ui";

import { DataGrid } from "../../shared/DataGrid";
import Spinner from "../../shared/LogoSpinner/Spinner";
import CreateSubject from "../../shared/Subject/CreateSubject";
import { useSubjects } from "../../../hooks/subjectHooks";
import useOverviewColumns from "../../../hooks/useOverviewColumns";
import { mostRecentCaseFilterModel } from "../../../lib/filterModels";
import { defaultColumnVisibilityModel } from "../../../lib/columnVisibilityModels";

type OverviewRow = {
  subject: Subject;
  case?: Case;
};

const TERMINAL_STATUSES = ["CANCELLED", "COMPLETED"];

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "types",
    headerName: "Types",
    children: [{ field: "subject.subjectType" }, { field: "case.caseType" }],
  },
  {
    groupId: "subject",
    headerName: "Subject",
    children: [
      { field: "subject.subjectData.score" },
      { field: "subject.location.parcelId" },
      { field: "subject.externalId" },
      { field: "subject.contact.primaryName" },
      { field: "subject.contact.naics" },
      { field: "subject.location.countyName" },
      { field: "subject.location.jurisdictionName" },
      { field: "subject.location.address" },
      { field: "subject.location.city" },
      { field: "subject.location.stateAbbr" },
      { field: "subject.location.zipCode" },
      { field: "subject.contact.website" },
      { field: "subject.contact.email" },
      { field: "subject.contact.phoneNumber" },
    ],
  },
  {
    groupId: "case",
    headerName: "Case",
    children: [
      { field: "status" },
      { field: "case.cycle" },
      { field: "case.location.parcelId" },
      { field: "case.location.countyName" },
      { field: "case.location.jurisdictionName" },
      { field: "case.assignee.displayName" },
      { field: "case.scheduledOn" },
      { field: "case.confirmationSentOn" },
      { field: "case.documentsReceivedOn" },
      { field: "case.primaryContact.displayName" },
      { field: "case.primaryContact.phoneNumber" },
      { field: "case.primaryContact.email" },
      { field: "case.location.address" },
      { field: "case.location.city" },
      { field: "case.location.stateAbbr" },
      { field: "case.location.zipCode" },
      { field: "mostRecent" },
      { field: "active" },
      { field: "details" },
    ],
  },
];

const Overview: FC = () => {
  const { error, loading, subjects } = useSubjects({ loadCases: true });
  const columns = useOverviewColumns();
  const { toggleDrawer } = useDrawer();

  const rows: OverviewRow[] = useMemo(
    () =>
      (subjects || []).flatMap((subject) => {
        const cases = [...(subject.cases || [])].sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : -1
        );

        if (cases.length === 0) {
          return { id: subject.id, subject, mostRecent: true, active: false };
        }

        return cases.map((c, i) => ({
          id: c.id,
          subject,
          case: c,
          mostRecent: i === 0,
          active: !TERMINAL_STATUSES.includes(c.status as string),
        }));
      }),
    [subjects]
  );

  const handleFabClick = useCallback(() => {
    toggleDrawer("createSubject");
  }, [toggleDrawer]);

  if (loading || error) return <Spinner />;
  if (!subjects) return null;

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item xs lg={12}>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              columnGroupingModel={columnGroupingModel}
              settingsPrefix="overview"
              defaultFilterModel={mostRecentCaseFilterModel}
              defaultColumnVisibilityModel={defaultColumnVisibilityModel}
            />
          </Paper>
          <Fab
            sx={{ position: "fixed", bottom: "4rem", right: "6rem", boxShadow: 8 }}
            color="info"
            aria-label="add subject"
            onClick={handleFabClick}
            title="Add Subject"
            type="button"
          >
            <AddIcon />
          </Fab>
          <CustomDrawer
            title="Create Subject"
            drawerId="createSubject"
            content={<CreateSubject />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Overview;
