import { FC } from "react";
import { Field, FieldProps, Form, useFormikContext } from "formik";
import { Box, Button, Chip, Divider, Grid } from "@mui/material";

import { CheckboxField, DecimalField } from "auditaware-ui";
import { Case, CaseStatus, caseStatusNames, Subject } from "auditaware-types";

import { SelectField } from "auditaware-ui";
import CaseTypeSelect from "../../shared/Form/CaseTypeSelect";
import UsStateSelect from "../../shared/Form/UsStateSelect";
import AssigneeSelect, { UserSelect } from "../../shared/Form/AssigneeSelect";
import { CaseResultTypeSelect } from "../../shared/SelectCaseResultType";
import GridDateField from "../../shared/GridDateField";
import GridTextField from "../../shared/GridTextField";

type CaseFormProps = {
  closeDrawer: () => void;
  subject: Subject;
  isEditMode: boolean;
};

const statusOptions = Object.values(CaseStatus).map((value) => ({
  display: caseStatusNames[value],
  value,
}));

const caseCycleOptions = [
  { display: "Annual", value: "Annual" },
  { display: "Quarterly - Q1", value: "Q1" },
  { display: "Quarterly - Q2", value: "Q2" },
  { display: "Quarterly - Q3", value: "Q3" },
  { display: "Quarterly - Q4", value: "Q4" },
];

const SectionDivider = ({ title }: { title: string }) => (
  <Grid item sm={12} my={1}>
    <Divider>
      <Chip label={title} />
    </Divider>
  </Grid>
);

const CaseForm: FC<CaseFormProps> = ({ closeDrawer, subject, isEditMode }) => {
  const { isSubmitting } = useFormikContext<Case>();

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {isEditMode ? (
            <SelectField options={statusOptions} label="Status" name="status" />
          ) : (
            <SelectField
              name="status"
              label="Status"
              options={[
                {
                  value: CaseStatus.New,
                  display: caseStatusNames[CaseStatus.New],
                },
              ]}
              disabled={!isEditMode}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <CaseTypeSelect />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField options={caseCycleOptions} label="Case Cycle" name="cycle" />
        </Grid>
        <GridDateField name="scheduledOn" label="Case Schedule Date" xs={12} md={6} />
        <GridDateField
          name="confirmationSentOn"
          label="Confirmation Sent Date"
          xs={12}
          md={12}
        />
        <GridDateField
          name="documentsReceivedOn"
          label="Documents Received Date"
          xs={12}
          md={12}
        />
        <Grid item xs={12} md={12}>
          <AssigneeSelect />
        </Grid>

        <SectionDivider title="Location" />
        <Field as={null}>
          {({ form: { values } }: FieldProps<Case>) => {
            const disabled = values.locationId === subject.locationId;
            const isNew = !values.id;

            return (
              <>
                <Grid item xs={12}>
                  <CheckboxField
                    name="locationId"
                    label="Location Same as Subject"
                    onValue={subject.locationId}
                    undefinedOff={isNew || disabled}
                  />
                </Grid>
                <GridTextField name="location.address" xs={12} disabled={disabled} />
                <GridTextField name="location.city" xs={12} disabled={disabled} />
                <Grid item xs={12} md={6} lg={6}>
                  <UsStateSelect disabled={disabled} name="location.stateAbbr" />
                </Grid>
                <GridTextField
                  name="location.zipCode"
                  disabled={disabled}
                  xs={12}
                  md={6}
                  lg={6}
                />
                <GridTextField
                  label="Parcel ID"
                  name="location.parcelId"
                  disabled={disabled}
                  xs={12}
                />
              </>
            );
          }}
        </Field>
        <SectionDivider title="Results" />
        <Grid item xs={12}>
          <CaseResultTypeSelect name="caseResultTypeId" label="Result" />
        </Grid>
        <GridDateField
          name="resultsLetterSentOn"
          label="Results Letter Sent On"
          xs={12}
        />
        <GridTextField name="resultSummary" label="Result Summary" xs={12} />
        <GridDateField name="reviewCompletedOn" label="Review Completed On" xs={12} />
        <Grid item xs={12}>
          <UserSelect name="reviewedByUid" label="Reviewed By" />
        </Grid>

        <SectionDivider title="Fees" />
        <GridTextField name="feeSize" xs={12} />
        <Grid item xs={12}>
          <DecimalField
            name="feeAmount"
            type="number"
            id="feeAmount"
            label="Fee Amount"
            fullWidth
          />
        </Grid>
        <GridDateField name="feeInvoicedOn" xs={12} />
      </Grid>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "800px",
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          padding: ".5rem",
          zIndex: 1,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="success"
          disabled={isSubmitting}
          fullWidth={true}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          disabled={isSubmitting}
          fullWidth={true}
          sx={{ ml: 2 }}
          onClick={closeDrawer}
          type="reset"
        >
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

export default CaseForm;
