import React from "react";
import { styled } from "@mui/material/styles";

import { CaseStatus, caseStatusNames } from "auditaware-types";

const StyledStatuses = styled("span")(({ theme }) => ({
  fontWeight: 700,
  "&.in-progress": {
    color: theme.palette.secondary.main,
  },
  "&.review": {
    color: theme.palette.info.main,
  },
  "&.completed": {
    color: theme.palette.success.main,
  },
}));

export const PrettyStatus = ({ status }: { status: CaseStatus }) => {
  const statusClass = status.toLowerCase().replace("_", "-");
  const prettyStatus = caseStatusNames[status] || caseStatusNames[CaseStatus.New];

  return (
    <StyledStatuses className={statusClass}>
      {prettyStatus}
    </StyledStatuses>
  );
};
