import React, { FC } from "react";
import { Avatar, lighten } from "@mui/material";

import { User } from "auditaware-types";

interface UserUidProps {
  user: User;
}

const stringToColor = (string: string): string => {
  const hash = Array.from(string).reduce(
    (acc, char) => char.charCodeAt(0) + ((acc << 5) - acc),
    0
  );
  const colors = [16, 8, 0].map((shift) =>
    `00${((hash >> shift) & 0xff).toString(16)}`.slice(-2)
  );

  return `#${colors.join("")}`;
};

const getInitials = (displayName: string) => {
  if (!displayName) {
    return "";
  }

  return displayName
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();
};

const InitialsAvatar: FC<UserUidProps> = ({ user }) => {
  const initials = user.displayName ?
    getInitials(user.displayName) :
    user.email.slice(0, 2).toUpperCase();

  const bgColor = stringToColor(initials);

  return (
    <Avatar
      alt={initials}
      sx={{
        marginTop: "0px",
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ?
            lighten(bgColor, 0.5) :
            bgColor,
      }}
    >
      {initials}
    </Avatar>
  );
};

export default InitialsAvatar;
