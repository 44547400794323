import { createContext } from "react";

export interface AnchorOrigin {
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
}

export const defaultAnchorOrigin: AnchorOrigin = {
  vertical: "top",
  horizontal: "right",
};

interface SnackBarContextProps {
  setSuccess: (message: string) => void;
  setError: (message: string) => void;
  setInfo: (message: string) => void;
  setWarning: (message: string) => void;
  setAnchorOrigin: (anchorOrigin: AnchorOrigin) => void;
}

export const SnackBarContext = createContext<SnackBarContextProps>({
  setSuccess: () => {},
  setError: () => {},
  setInfo: () => {},
  setWarning: () => {},
  setAnchorOrigin: () => {},
});
