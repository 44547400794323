"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const yup_1 = require("yup");
(0, yup_1.addMethod)(yup_1.string, "isRecentYear", function isRecentYear(startYear = 1900, errorMessage = `Year must be between ${startYear} and the current year`) {
    // `addMethod` from Yup ensures that `this` will be correctly set.
    // eslint-disable-next-line no-invalid-this
    return this
        .matches(/^\d{4}$/, "Year format must be 'YYYY'")
        .test("isRecentYear", errorMessage, (value) => {
        if (!value)
            return true;
        const year = parseInt(value);
        const currentYear = new Date().getFullYear();
        return year >= startYear && year <= currentYear;
    });
});
