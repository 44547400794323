import { Formik } from "formik";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";

import Form from "./SubjectForm";
import { SubjectInput, SubjectSchema as validationSchema } from "auditaware-types";
import { useSubject, useUpdateSubject } from "../../../hooks/subjectHooks";
import { useSnackbarContext } from "../../../hooks/useSnackbarContext";
import { useDrawer } from "auditaware-ui";

const Edit: FC = () => {
  const { subjectId = "" } = useParams();
  const { data } = useSubject(subjectId);
  const { setSuccess, setError } = useSnackbarContext();
  const { toggleDrawer } = useDrawer();

  const [update] = useUpdateSubject();

  const handleSubmit = useCallback(
    async (values: SubjectInput) => {
      // remove subject data until api supports it
      // eslint-disable-next-line no-unused-vars
      const { subjectData, ...input } = values;
      try {
        await update({ variables: { id: subjectId, input } });
        setSuccess("Subject saved successfully");
        toggleDrawer("editSubject");
      } catch (err) {
        console.error(err);
        setError("Subject failed to save");
      }
    },
    [update, subjectId, setError, setSuccess, toggleDrawer]
  );

  const subject = data?.organization?.subject;
  if (!subject) return null;

  // eslint-disable-next-line no-unused-vars
  const { id, organizationId, createdByUid, locationId, cases, ...initialValues } =
    subject;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form drawerId="editSubject" />
    </Formik>
  );
};

export default Edit;
