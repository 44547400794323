import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";

import { useOrganization } from "auditaware-auth";
import { User } from "auditaware-types";

type GetAssigneesResponse = {
  organization: {
    assignees: User[];
  };
};
const GET_ASSIGNEES = gql`
  query GetAssignees($organizationId: ID!) {
    organization(id: $organizationId) {
      assignees {
        displayName
        id
        email
      }
    }
  }
`;

const useAssignees = () => {
  const org = useOrganization();
  const { data, error } = useQuery<GetAssigneesResponse>(
    GET_ASSIGNEES,
    {
      variables: { organizationId: org.id },
      // can't cache as there is an issue refreshing the parent case
      fetchPolicy: "no-cache",
    },
  );

  if (error) {
    console.error(error);
  }
  return data?.organization.assignees || [];
};

export default useAssignees;
