import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Form from "./SubjectForm";

// import { useUserDoc } from "@reasongcp/react-fire-sub";
import { SubjectSchema as validationSchema } from "auditaware-types";
import { useSnackbarContext } from "../../../hooks/useSnackbarContext";
import { useDrawer } from "auditaware-ui";
import { SubjectInput } from "auditaware-types";
import { useCreateSubject } from "../../../hooks/subjectHooks";

const initialValues = {
  subjectType: "",
  externalId: null,
  firstAuditYear: "",
  contact: {
    primaryName: "",
    secondaryName: "",
    address: "",
    city: "",
    stateAbbr: "",
    zipCode: "",
    phoneNumber: "",
    email: "",
    website: "",
    naics: "",
  },
  location: {
    address: "",
    city: "",
    stateAbbr: "",
    zipCode: "",
    countyName: "",
    jurisdictionName: "",
    parcelId: null,
  },
};

const NewSubject: FC = () => {
  const { setSuccess, setError } = useSnackbarContext();
  const navigate = useNavigate();
  const { toggleDrawer } = useDrawer();
  const [createSubject] = useCreateSubject();

  const handleSubmit = useCallback(
    async (values: SubjectInput) => {
      // remove subject data until api supports it
      // eslint-disable-next-line no-unused-vars
      const { subjectData, ...input } = values;
      const variables = { input };
      try {
        const res = await createSubject({ variables });

        const id = res.data?.createSubject?.id;
        setSuccess("Subject created successfully");
        navigate(`/details/${id}`);
        toggleDrawer("createSubject");
      } catch (err) {
        console.error(err);
        setError("Subject failed to create");
      }
    },
    [createSubject, setError, setSuccess, navigate, toggleDrawer]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form drawerId="createSubject" />
    </Formik>
  );
};

export default NewSubject;
