import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";

import { CaseResultType } from "auditaware-types";
import { useOrganization } from "auditaware-auth";

type GetCaseResultTypesResponse = {
  organization: {
    caseResultTypes: CaseResultType[];
  };
};

const GET_CASE_RESULT_TYPES = gql`
  query GetCaseResultTypes($organizationId: ID!) {
    organization(id: $organizationId) {
      caseResultTypes {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const useCaseResultTypes = () => {
  const org = useOrganization();
  const { data, error } = useQuery<GetCaseResultTypesResponse>(
    GET_CASE_RESULT_TYPES,
    {
      variables: { organizationId: org.id },
      // NOTE: For some reason caching this query causes the Case object to
      // not update in the cache when the updateCase mutation returns. Since
      // the query is small, and just runs once to populate a dropdown, don't
      // cache it.
      fetchPolicy: "no-cache",
    },
  );

  if (error) {
    console.error(error);
  }
  return data?.organization.caseResultTypes || null;
};
