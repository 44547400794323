import { FC } from "react";
import { Item, List } from "../../shared/List";
import { OrganizationContact, Subject } from "auditaware-types";

type OrganizationViewProps = {
  firstAuditYear?: Subject["firstAuditYear"];
  naics: OrganizationContact["naics"];
};

const OrganizationView: FC<OrganizationViewProps> = ({ firstAuditYear, naics }) => {
  return (
    <List>
      <Item primary="Year Opened" secondary={firstAuditYear} />
      <Item primary="NAICS" secondary={naics} />
    </List>
  );
};

export default OrganizationView;
