import { useCallback } from "react";

import { Case, CaseInput, Subject } from "auditaware-types";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "./useSnackbarContext";

const dateFields = [
  "confirmationSentOn",
  "documentsReceivedOn",
  "scheduledOn",
  "resultsLetterSentOn",
  "reviewCompletedOn",
  "feeInvoicedOn",
] as const;

const cleanDateFields = <T extends Case | CaseInput>(values: T) => {
  return dateFields.reduce(
    (acc, field) => {
      const value = values[field] === "" ? null : values[field];
      return { ...acc, [field]: value };
    },
    { ...values }
  );
};

const postProcessFormikValues = <T extends Case | CaseInput>(
  subjectLocationId: string,
  values: T
) => {
  const updatedValues = cleanDateFields(values);
  // Omit the location when same as Subject.
  const isLocationShared = values.locationId === subjectLocationId;
  const location = isLocationShared ? undefined : values.location;

  return { ...updatedValues, location };
};

const useHandleSubmit = <T extends Case | CaseInput>(
  saveFn: (values: T) => Promise<{ data: Case }>,
  subject: Subject,
  closeDrawer: () => void
) => {
  const navigate = useNavigate();
  const { setSuccess, setError } = useSnackbarContext();

  return useCallback(
    (values: T) => {
      const cleanedValues = postProcessFormikValues(subject.locationId, values);

      return saveFn(cleanedValues)
        .then(({ data }) => {
          setSuccess("Case saved successfully!");
          navigate(`/details/${subject.id}/${data.id}`);
          closeDrawer();
        })
        .catch((err) => {
          console.error(err);
          setError("Error saving case!");
        });
    },
    [saveFn, navigate, subject.id, subject.locationId, setSuccess, setError, closeDrawer]
  );
};

export default useHandleSubmit;
