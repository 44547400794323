import { useState, ReactNode, useMemo, useCallback } from "react";
import { Alert, Snackbar } from "@mui/material";
import {
  AnchorOrigin,
  SnackBarContext,
  defaultAnchorOrigin,
} from "./SnackBarContext";

type SnackBarSeverity = "success" | "error" | "info" | "warning";

interface SnackBarProviderProps {
  children: ReactNode;
}

const SnackBarProvider = ({ children }: SnackBarProviderProps) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<SnackBarSeverity>("success");
  const [anchorOrigin, setAnchorOrigin] =
    useState<AnchorOrigin>(defaultAnchorOrigin);

  const handleClose = () => {
    setOpen(false);
  };

  const setSuccess = useCallback((message: string) => {
    setMessage(message);
    setSeverity("success");
    setOpen(true);
  }, []);

  const setError = useCallback((message: string) => {
    setMessage(message);
    setSeverity("error");
    setOpen(true);
  }, []);

  const setInfo = useCallback((message: string) => {
    setMessage(message);
    setSeverity("info");
    setOpen(true);
  }, []);

  const setWarning = useCallback((message: string) => {
    setMessage(message);
    setSeverity("warning");
    setOpen(true);
  }, []);

  const providerValue = useMemo(() => {
    return {
      setSuccess,
      setError,
      setInfo,
      setWarning,
      setAnchorOrigin,
    };
  }, [setAnchorOrigin, setError, setInfo, setSuccess, setWarning]);

  return (
    <SnackBarContext.Provider value={providerValue}>
      {children}
      <Snackbar
        anchorOrigin={anchorOrigin}
        autoHideDuration={6000}
        open={open}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;
