import { FC } from "react";
import { SelectField, SelectFieldProps } from "auditaware-ui";
import { SUBJECT_TYPES } from "auditaware-types";

export const options = [...SUBJECT_TYPES];

const SubjectTypeSelect: FC<Omit<SelectFieldProps, "name" | "label">> = ({
  required = false,
  id,
  autoComplete,
}) => {
  const name = "subjectType";
  const label = "Subject Type";

  const props = {
    name,
    required,
    id,
    label,
    autoComplete,
    options,
  };
  return <SelectField {...props} />;
};

export default SubjectTypeSelect;
