import React from "react";

export const usePersistentState = <T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] => {
  const [value, setValue] = React.useState<T>(() => {
    const existingValue = localStorage.getItem(key);
    try {
      return existingValue ? JSON.parse(existingValue) : initialValue;
    } catch (e) {
      console.error(`Error parsing localStorage for key ${key}`, e);
      return initialValue;
    }
  });

  const setValueWithPersistence = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  };

  return [value, setValueWithPersistence];
};
