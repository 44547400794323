import { FC, ReactNode } from "react";
import { Avatar, Tooltip, styled } from "@mui/material";

import { CaseType, SubjectType } from "auditaware-types";

import { caseIconFromType, subjectIconFromType } from "../../../lib/appTypeIcons";
import { wordToCamelCase } from "../../../lib/wordToCamelCase";
import { useTheme } from "@mui/material/styles";
import { caseColorFromTheme, subjectColorFromTheme } from "../../../lib/appTypeColors";

const AvatarStyled = styled(Avatar, {
  shouldForwardProp: (prop) => true,
})(({ color }) => ({
  backgroundColor: color,
  cursor: "pointer",
  height: 24,
  width: 24,
}));

const AvatarWrapper: FC<{
  typeName: string | null;
  color: string;
  children: ReactNode;
}> = ({ typeName, color, children }) => {
  const className = wordToCamelCase(typeName || "");
  return (
    <Tooltip title={typeName}>
      <AvatarStyled className={className} color={color}>
        {children}
      </AvatarStyled>
    </Tooltip>
  );
};

export const CaseIcon: FC<{ caseType: CaseType | null }> = ({ caseType }) => {
  const TypeIcon = caseIconFromType(caseType);
  return <TypeIcon style={{ height: 18, width: 18 }} />;
};

export const CaseAvatar: FC<{ caseType: CaseType | null }> = ({ caseType }) => {
  const theme = useTheme();
  const color = caseColorFromTheme(theme, caseType);
  return (
    <AvatarWrapper typeName={caseType} color={color}>
      <CaseIcon caseType={caseType} />
    </AvatarWrapper>
  );
};

export const SubjectIcon: FC<{ subjectType: SubjectType | null }> = ({ subjectType }) => {
  const TypeIcon = subjectIconFromType(subjectType);
  return <TypeIcon style={{ height: 18, width: 18 }} />;
};

export const SubjectAvatar: FC<{ subjectType: SubjectType | null }> = ({
  subjectType,
}) => {
  const theme = useTheme();
  const color = subjectColorFromTheme(theme, subjectType);
  return (
    <AvatarWrapper typeName={subjectType} color={color}>
      <SubjectIcon subjectType={subjectType} />
    </AvatarWrapper>
  );
};
