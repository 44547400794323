import { useContext } from "react";
import { SnackBarContext } from "../components/shared/SnackBar/SnackBarContext";

export const useSnackbarContext = () => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error(
      "useSnackbarContext must be used within a SnackbarProvider"
    );
  }
  return context;
};
