import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Reports: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/reports/status");
  }, [navigate]);

  return null;
};

export default Reports;
