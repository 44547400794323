import { FC } from "react";
import { Link } from "react-router-dom";

import {
  Avatar,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Download from "@mui/icons-material/Download";

import { Attachment } from "auditaware-types";

import useCoercedDateScalar from "../../../hooks/useCoercedDateScalar";

type AttachmentItemProps = {
  attachment: Attachment;
};

const AttachmentItem: FC<AttachmentItemProps> = ({ attachment }) => {
  const {
    fileName,
    description,
    id,
    createdAt,
    url,
  } = attachment;
  const safeCreatedAt = useCoercedDateScalar(createdAt);

  return (
    <ListItem
      key={id}
      divider={true}
      alignItems="flex-start"
      secondaryAction={
        <>
          <IconButton edge="end" aria-label="view attachment">
            <Link
              to={url}
              style={{ color: "inherit" }}
            >
              <Download />
            </Link>
          </IconButton>
          <IconButton edge="end" aria-label="view attachment">
            <Link
              to={url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit" }}
            >
              <OpenInNewIcon />
            </Link>
          </IconButton>
        </>
      }
      disablePadding
    >
      <ListItemButton sx={{ p: 2 }}>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={description || fileName}
          secondary={
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
            >
              <small>
                {safeCreatedAt.toLocaleString()}
              </small>
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default AttachmentItem;
