import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Note, NoteInput } from "auditaware-types";

import { GET_SUBJECT } from "./subjectHooks";
import { GET_CASE } from "./cases";

export type CreateNoteResponse = {
  createNote: Note;
}

export type UpdateNoteResponse = {
  updateNote: Note;
}

export const noteFragment = gql`
  fragment NoteFragment on Note {
    id
    createdAt
    updatedAt
    text
    author {
      id
      email
      displayName
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateNote($caseId: ID!, $input: NoteInput!) {
    createNote(caseId: $caseId, input: $input) {
      ...NoteFragment
    }
  }
  ${noteFragment}
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($id: ID!, $input: NoteInput!) {
    updateNote (id: $id, input: $input) {
      ...NoteFragment
    }
  }
  ${noteFragment}
`;

export const useCreateNote = (caseId: string) => {
  const [
    createNote,
    { error, data, ...rest },
  ] = useMutation<CreateNoteResponse>(CREATE_NOTE, {
    variables: { caseId },
    refetchQueries: [GET_CASE, GET_SUBJECT],
  });

  if (error) {
    console.error(error);
  }

  const updateFn = (input: NoteInput) => {
    return createNote({ variables: { input } });
  };

  const note = data?.createNote;
  const resultData = { error, note, ...rest };
  return [updateFn, resultData] as const;
};

export const useUpdateNote = (id: string) => {
  const [
    updateNote,
    { error, data, ...rest },
  ] = useMutation<UpdateNoteResponse>(UPDATE_NOTE, {
    variables: { id },
    refetchQueries: [GET_CASE],
  });

  if (error) {
    console.error(error);
  }

  const updateFn = (note: Note) => {
    const input = { text: note.text };
    return updateNote({ variables: { input } });
  };

  const note = data?.updateNote;
  const resultData = { error, note, ...rest };
  return [updateFn, resultData] as const;
};
