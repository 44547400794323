import { Formik } from "formik";
import { FC } from "react";

import {
  Case,
  CaseSchema as validationSchema,
  CaseStatus,
  Subject
} from "auditaware-types";

import ContactsForm from "./ContactsForm";
import { useUpdateCase } from "../../../hooks/cases";
import useHandleSubmit from "../../../hooks/useHandleSubmitCase";

type EditProps = {
    caseData: Case;
    subject: Subject;
    closeDrawer: () => void;
};

const EditContacts: FC<EditProps> = ({ caseData, subject, closeDrawer }) => {
  const caseId = caseData.id;
  const [updateCase] = useUpdateCase(caseId);
  const handleSubmit = useHandleSubmit(
    updateCase,
    subject,
    closeDrawer,
  );

  if (!caseData) return null;

  const initialValues = {
    ...caseData,
    status: CaseStatus.New
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ContactsForm
        closeDrawer={closeDrawer}
        subject={subject}
        isEditMode={true}
      />
    </Formik>
  );
};

export default EditContacts;
