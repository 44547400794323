/* eslint-disable max-len */
import { Box } from "@mui/material";
import { FC } from "react";
import { BluePin, GoldPin, GreenPin, OrangePin, PurplePin, RedPin } from "./Pins";

export interface MenuItemProps {
  className?: string;
  height?: number;
  width?: number;
  viewBox?: string;
}

const Spinner: FC<MenuItemProps> = ({
  className,
  height = 180,
  width = 180,
  viewBox = "0 0 252.16 276.86",
}) => {
  return (
    <Box my={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <svg
        className={"spinnerWrapper"}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        height={height}
        width={width}
      >
        <svg
          className={`spinner ${className}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox}
          height={height}
          width={width}
        >
          <GoldPin />
          <BluePin />
          <GreenPin />
          <PurplePin />
          <RedPin />
          <OrangePin />
          <circle className="cls-2" cx="126.08" cy="139.94" r="7.57" />
        </svg>
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from={"0 0 0"}
          to={"360 0 0"}
          dur="5s"
          repeatCount="indefinite"
        />
      </svg>
    </Box>
  );
};

export default Spinner;
