interface AllPins {
  gold: PinProps;
  blue: PinProps;
  green: PinProps;
  purple: PinProps;
  red: PinProps;
  orange: PinProps;
}

interface PinProps {
  id: string;
  pin: string;
}

/* eslint-disable max-len */
const svgPins: AllPins = {
  gold: {
    id: "gold-pin",
    pin: "m125.38,120.37C214.91,3.53,126.39,1.5,126.39,1.5c0,0-88.54.53-1.01,118.87Z",
  },
  blue: {
    id: "blue-pin",
    pin: "m141.37,128.79c145.94,19.12,103.44-58.56,103.44-58.56,0,0-44.73-76.41-103.44,58.56Z",
  },
  green: {
    id: "green-pin",
    pin: "m142.08,146.85c56.42,135.95,102.44,60.3,102.44,60.3,0,0,43.81-76.95-102.44-60.3Z",
  },
  purple: {
    id: "purple-pin",
    pin: "m126.79,156.49c-89.53,116.83-1.01,118.87-1.01,118.87,0,0,88.54-.53,1.01-118.87Z",
  },
  red: {
    id: "red-pin",
    pin: "m110.79,148.07C-35.15,128.95,7.35,206.63,7.35,206.63c0,0,44.73,76.41,103.44-58.56Z",
  },
  orange: {
    id: "orange-pin",
    pin: "m110.09,130.01C53.67-5.94,7.65,69.7,7.65,69.7c0,0-43.81,76.95,102.44,60.3Z",
  },
};
/* eslint-enable max-len */

export const GoldPin = () => {
  return <Pin {...svgPins.gold} />;
};

export const BluePin = () => {
  return <Pin {...svgPins.blue} />;
};

export const GreenPin = () => {
  return <Pin {...svgPins.green} />;
};

export const PurplePin = () => {
  return <Pin {...svgPins.purple} />;
};

export const RedPin = () => {
  return <Pin {...svgPins.red} />;
};

export const OrangePin = () => {
  return <Pin {...svgPins.orange} />;
};

const Pin = ({ id, pin }: PinProps) => {
  return (
    <g className="pin">
      <path id={id} d={pin} />
    </g>
  );
};
