import {
  Business,
  Person,
  QuestionMark,
  FactCheck,
  Pageview,
  Grading,
  PriceCheck,
  Explore,
  HomeWork,
  Home,
  Edit,
} from "@mui/icons-material";

import { SubjectType, CaseType } from "auditaware-types";

export const EditIcon = Edit;

const mapCaseTypeToIcon = {
  Audit: FactCheck,
  Inspection: Pageview,
  Review: Grading,
  Appraisal: PriceCheck,
  Discovery: Explore,
  ESA: HomeWork,
};

const mapSubjectTypeToIcon = {
  Organization: Business,
  Individual: Person,
  Residence: Home,
};

export const caseIconFromType = (caseType: CaseType | null) => {
  if (caseType === null) {
    return QuestionMark;
  }
  return mapCaseTypeToIcon[caseType] || QuestionMark;
};

export const subjectIconFromType = (subjectType: SubjectType | null) => {
  if (subjectType === null) {
    return QuestionMark;
  }
  return mapSubjectTypeToIcon[subjectType] || QuestionMark;
};
