import { FC, PropsWithChildren } from "react";
import { Typography } from "@mui/material";

const BoxHeader: FC<PropsWithChildren> = ({ children }) => {
  const styles = {
    p: 2,
    display: "flex",
    alignItems: "center",
  };

  return (
    <Typography component="h1" variant="h5" sx={styles}>
      {children}
    </Typography>
  );
};

export default BoxHeader;
