import { FC, ReactNode } from "react";
import { SubjectType } from "auditaware-types";

type SwitcherMap = Record<SubjectType, ReactNode>;

type SubjectTypeSwitcherProps = {
  subjectType: SubjectType;
  switcherMap: SwitcherMap;
};
const SubjectTypeSwitcher: FC<SubjectTypeSwitcherProps> = ({
  subjectType,
  switcherMap,
}) => {
  return switcherMap[subjectType];
};

export default SubjectTypeSwitcher;
