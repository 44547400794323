import { FC, Dispatch, SetStateAction } from "react";
import { Box, Divider, Typography } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarProps,
  GridColumnVisibilityModel,
  GridFilterModel,
} from "@mui/x-data-grid-pro";
import DataGridToolbarIcons from "./DataGridToolbarIcons";

declare module "@mui/x-data-grid-pro" {
  // eslint-disable-next-line
  interface ToolbarPropsOverrides {
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
    setColumnVisibilityModel: (x: GridColumnVisibilityModel | null) => void;
    setFilters: (x: GridFilterModel | null) => void;
  }
}

import { ToolbarPropsOverrides } from "@mui/x-data-grid-pro";

type DataGridToolbarProps = GridToolbarProps & ToolbarPropsOverrides;

export const DataGridToolbar: FC<DataGridToolbarProps> = ({
  setColumnVisibilityModel,
  setAnchorEl,
  setFilters,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          <DataGridToolbarIcons
            setFilters={setFilters}
            setColumnVisibilityModel={setColumnVisibilityModel}
          />
        </Typography>
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton ref={setAnchorEl} />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </Box>
      <Divider light />
    </Box>
  );
};
