import { FC, ReactNode } from "react";
import { SxProps, Tooltip, Typography } from "@mui/material";

interface GridItemProps {
  sx?: SxProps;
  children: ReactNode;
  title?: ReactNode;
  variant?: any;
}

const GridItem: FC<GridItemProps> = ({ children, sx, title, variant }) => {
  return (
    <Tooltip title={title}>
      <Typography noWrap variant={variant} sx={sx}>
        {children}
      </Typography>
    </Tooltip>
  );
};

export default GridItem;
