import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { IconButton, styled, Typography, Link as MUILink } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValueGetter,
} from "@mui/x-data-grid-pro";

import useFormattedDate from "../../hooks/useFormattedDate";

import FirebaseTimestamp from "./FirebaseTimestamp";
import GridItem from "./AllListGrid/GridItem";

export const ArrowIconStyled = styled(ArrowForwardIos)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#fff" : "rgb(95,99,104)",
  height: 18,
  width: 18,
}));

export const renderNoHeader = (params: GridColumnHeaderParams) => null;

export const renderHeader = (params: GridColumnHeaderParams) => (
  <Typography variant="overline" noWrap color="text.secondary">
    {params.colDef.headerName}
  </Typography>
);

export const renderCell = (
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) => {
  const value = params.formattedValue;
  return (
    <GridItem title={value} variant="body2">
      {value}
    </GridItem>
  );
};

export const renderTimestampCell = (
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) => {
  const value = params.formattedValue;
  return (
    <Typography component="span" variant="body2" noWrap>
      <FirebaseTimestamp timestamp={value} />
    </Typography>
  );
};

const DateCell: React.FC<{ date: Date }> = ({ date }) => {
  return useFormattedDate(date);
};

export const renderDateCell = (
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) => {
  const value = params.formattedValue;

  return (
    <Typography component="span" variant="body2" noWrap>
      <GridItem variant="body2">
        <DateCell date={value} />
      </GridItem>
    </Typography>
  );
};

export const renderLinkCell = (
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) => {
  return (
    <MUILink component={RouterLink} to={params.value} underline="none" color="inherit">
      <IconButton aria-label="View Button" sx={{ height: 24, width: 24 }}>
        <ArrowIconStyled />
      </IconButton>
    </MUILink>
  );
};

export const nestedValueGetter: GridValueGetter = (value, row, colDef) => {
  const dottedKey = colDef.field;

  // eslint-disable-next-line max-params
  return dottedKey.split(".").reduce((acc, key, idx, arr) => {
    if (idx === arr.length - 1) {
      return acc[key];
    }

    return acc[key] || {};
  }, row);
};

export const timestampValueGetter: GridValueGetter = (value, row, colDef) => {
  const key = colDef.field;
  const { seconds, nanoseconds } = row[key] || {};

  return new Date(seconds * 1000 + nanoseconds / 1000000);
};
