import { useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import { gql } from "@apollo/client";

import { RoleGuard, SSOAuthBoundary } from "@reasongcp/react-fire-sub";

import { useApolloClientWithAuth } from "auditaware-auth";
import { CMRoles } from "auditaware-types";
import { Layout } from "auditaware-ui";

import Overview from "../../pages/Overview";
import Details from "../../pages/Details";
import Reports from "../../pages/Reports";
import StatusReport from "../../pages/Reports/StatusReport";

import useConfig from "../../../hooks/useConfig";

import {
  OrganizationProvider,
  NoAuth,
  LoginRedirect,
  LoadingPlaceholder,
} from "auditaware-auth";

const pages = [
  { id: 0, name: "Cases", link: "/" },
  { id: 1, name: "Reports", link: "/reports" },
];

const AppRoutes = () => {
  const { fireSubApi, ssoClient } = useConfig();
  const firesubClient = useApolloClientWithAuth(fireSubApi, false);

  const LR = useCallback(() => <LoginRedirect ssoClient={ssoClient} />, [ssoClient]);
  const NA = useCallback(() => <NoAuth ssoClient={ssoClient} />, [ssoClient]);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <SSOAuthBoundary
            gql={gql}
            client={firesubClient}
            InvalidPlaceHolder={LR}
            LoadingPlaceHolder={LoadingPlaceholder}
          >
            <OrganizationProvider>
              <Layout appName="Case Manager" ssoClient={ssoClient} pages={pages}>
                <RoleGuard roles={[...CMRoles] as string[]} NoAuth={NA}>
                  <Routes>
                    <Route path="reports" element={<Reports />} />
                    <Route path="reports/status" element={<StatusReport />} />
                    <Route path="/" element={<Overview />} />
                    <Route path="/details/:subjectId/:caseId?" element={<Details />} />
                  </Routes>
                </RoleGuard>
              </Layout>
            </OrganizationProvider>
          </SSOAuthBoundary>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
