// coerceDateScalar takes the "Date" object (which is actually a string) and
// converts it to a real Date object.
//
// TODO: This is a hack for now. We should tell Apollo client how to parse
// Date and DateTime scalars.
//
// https://reasonconsulting.atlassian.net/browse/AA-605
export const coerceDateScalar = (date: Date) => {
  // Ignore the warning. It's wrong and intentionally so.
  // @ts-ignore
  return new Date(date as string);
};
