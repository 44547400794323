import { FC, PropsWithChildren } from "react";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";

const OverviewBox: FC<PropsWithChildren> = ({ children }) => {
  const overviewBgColor = {
    borderRadius: 1,
    my: 1,
    mb: 2,
    pb: 2,
    bgcolor: (theme: any) => (theme.palette.mode === "light" ? grey[100] : grey[800]),
    px: 2,
  };

  return <Box sx={overviewBgColor}>{children}</Box>;
};

export default OverviewBox;
